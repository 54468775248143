import React from "react";
import "./header.css";
import ai from "../../assets/ai.png";

const Header = () => {
  return (
    <div className="ai__header section__padding" id="home">
      <div className="ai__header-content">
        <h1 className="gradient__text">Unlock the future with AI&nbsp;Agents.</h1>
        <p>
          Empowering Tomorrow with AI Agents. At HUBRIS, we transform the potential
          of artificial intelligence. Our innovations break barriers, delivering
          unparalleled advancements in productivity and innovation.
          Discover the exceptional as we lead you into the future of intelligent automation.
        </p>
      </div>
      <div className="ai__header-image">
        <img src={ai} height={"10em"} alt="ai" />
      </div>
    </div>
  );
};

export default Header;
