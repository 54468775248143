import React from "react";
import "./whatAI.css";
import { Feature } from "../../components";

const WhatAI = () => {
  return (
    <div className="ai__whatai section__margin" id="ai">
      <div className="ai__whatai-feature">
        <Feature
          title="Key Benefits"
          text="Embark on a journey into the future with NexusAI, where artificial intelligence transcends boundaries. Our advanced solutions redefine the way you perceive technology, offering unparalleled possibilities for innovation and efficiency."
        />
      </div>
      <div className="ai__whatai-heading">
        <h1 className="gradient__text">
          Join the AI Revolution. Elevate Your Experience with HUBRIS.
        </h1>
        <p>Tommorow's technology, today</p>
      </div>
      <div className="ai__whatai-container">
        <Feature
          title="500k Numbers Done"
          text="Unlock profound insights with NexusAI, expanding your understanding of data and trends."
        />
        <Feature
          title="20x The Job Stuff"
          text="Redefine operational efficiency through streamlined processes and intelligent automation."
        />
        <Feature
          title="8000% Faster"
          text="Seamlessly integrate NexusAI into your workflows, enhancing collaboration and adaptability."
        />
      </div>
    </div>
  );
};

export default WhatAI;
